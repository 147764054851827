import { ActivatedRoute } from '@angular/router';
import { map, filter } from 'rxjs/operators';
import { Observable, Subscription, Subject } from 'rxjs';

import { Status } from 'src/app/shared/interfaces/status.interface';
import { UrlParams } from 'src/app/shared/utils/url';

export interface ResponsePagination<T> {
    count: number;
    next: string;
    previous: string;
    results: T[];
}

export interface FormSearchService<T> {
    formSearch(params: { [key: string]: any }): Observable<ResponsePagination<T>>;
}

const EVENT_FIRST_PAGE = 'firstPage';
const EVENT_OTHER_PAGES = 'otherPages';
const EVENT_STATUS = 'status';
const EVENT_ERROR = 'error';

export class Pagination<T> {
    private _nextParams: { [name: string]: any } = null;
    private _routeParams: { [name: string]: any };
    private _subRoute: Subscription;
    private _event: Subject<{ event: string, data: any }> = new Subject();
    private _busy = false;

    constructor(
        private route: ActivatedRoute,
        private service: FormSearchService<T>,
    ) {
        if (this.route) {
            this._subRoute = this.route.queryParams.subscribe(params => {
                this._routeParams = params;
                this._nextParams = null;
                this._busy = false;

                setTimeout(() => this._navigate(), 100);
            });
        } else {
            this._routeParams = {};
            this._nextParams = null;
            this._busy = false;
        }
    }

    public navigate(params: { [name: string]: any }, all: Boolean = false): void {
        this._routeParams = params;
        this._navigate(all);
    }

    public nextPage(showStatus: boolean = false) {
        this._nextPage(showStatus);
    }

    public destroy(): void {
        if (this._subRoute) {
            this._subRoute.unsubscribe();
        }
    }

    public get status(): Observable<Status> {
        return this._event.pipe(
            filter(event => event.event === EVENT_STATUS),
            map(event => event.data));
    }

    public get firstPage(): Observable<T[]> {
        return this._event.pipe(
            filter(event => event.event === EVENT_FIRST_PAGE),
            map(event => event.data));
    }

    public get otherPages(): Observable<T[]> {
        return this._event.pipe(
            filter(event => event.event === EVENT_OTHER_PAGES),
            map(event => event.data));
    }

    public get error(): Observable<any> {
        return this._event.pipe(
            filter(event => event.event === EVENT_ERROR),
            map(event => event.data));
    }

    private sendNext(event: string, data: any): void {
        this._event.next({ event, data });
    }

    private sendError(data: any): void {
        // this._event.error(" >> ERROR << ");
        this._event.next({ event: EVENT_ERROR, data });
    }

    private _navigate(all: Boolean = false): void {
        if (this._busy) { return; }
        this._busy = true;

        if (all || Object.keys(this._routeParams).length !== 0) {
            this.setNextPage(null);
            this.sendNext(EVENT_STATUS, { info: 'Carregando registros...', load: true });

            this.service.formSearch(Object.assign(this._routeParams, this._nextParams))
                .subscribe(res => {
                    this.setNextPage(res.next);
                    this.sendNext(EVENT_FIRST_PAGE, res.results);
                }, err => {
                    this.sendError(err);
                }, () => {
                    this._busy = false;
                });
        } else {
            this.sendNext(EVENT_STATUS, { info: 'Encontre o que deseja usando os filtros acima.' });
        }
    }

    private _nextPage(showStatus: boolean = false): void {
        if (this._busy || !this._nextParams) { return; }
        this._busy = true;

        if (showStatus) {
            this.sendNext(EVENT_STATUS, { info: 'Carregando registros...', load: true });
        }

        this.service.formSearch(this._nextParams)
            .subscribe(res => {
                this.setNextPage(res.next);
                this.sendNext(EVENT_OTHER_PAGES, res.results);
            }, err => {
                this.sendError(err);
            }, () => {
                this._busy = false;
            });
    }

    private setNextPage(url: string): void {
        this._nextParams = !url ? null : UrlParams(url);
    }
}
