export const UrlParams = (url: string) => {
  if (!url) { return; }

  return (url.split('?')[1] || '').split('&')
    .map(par => par.split('='))
    .reduce((res: { [name: string]: any }, param: string[]) => {
      const name = param[0];
      const value = decodeURIComponent(param[1]);

      if (!res[name]) {
        res[name] = value;
        return res;
      }

      if (Array.isArray(res[name])) {
        res[name].push(value);
        return res;
      }

      res[name] = [res[name], value];
      return res;
    }, {});
};
